import { useCallback, useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { useRouter } from 'next/navigation'
import * as Sentry from '@sentry/nextjs'
import { useSetAtom } from 'jotai'
import { useSWRConfig } from 'swr'
import { ACTION_TYPES, API_PATH, MOBILE_EVENTS, URL_PATH } from '@/consts'
import showPlusForm from '@/containers/Plus/utils'
import { useCreateBoardModal } from '@/hooks/useCreateBoardModal'
import { useModal } from '@/hooks/useModal'
import useToast from '@/hooks/useToast'
import { tokensAtom } from '@/stores'
import { isWebview, getVersionFromCookie } from '@/utils'
import { weblogout, setAuthToken } from '@/utils/auth'
import { handleNotificationRead } from '@/utils/notification'
import { parseURL } from '@/utils/url'
import { checkDetailPagePath } from './utils'

const boardPageRegex = /\/boards\/(\d+)/

/**
 * 웹뷰에서 전달받은 URL에서 중복된 호스트명을 제거한다.
 * 앱에서 수정해야 하나, 그러려면 앱 배포를 해야 하니, 웹에서 처리한다.
 * @param url
 * @returns 중복된 호스트명이 제거된 URL
 */
const cleanDuplicateHost = (url: string) => {
  // 호스트명 패턴 정의
  const hosts = [
    'https://www.d.lunit.care',
    'https://www.s.lunit.care',
    'https://www.lunit.care',
  ]

  // 호스트명 패턴을 OR 연산자로 연결한 정규 표현식 생성
  const hostPattern = hosts.map((host) => host.replace('.', '\\.')).join('|')
  const regex = new RegExp(`(${hostPattern})\\1`, 'g')

  // 중복된 호스트명 제거
  return url.replace(regex, '$1')
}

/**
 * 모바일 앱에서 보내는 메시지 처리
 */
const useAppMessage = () => {
  const isApp = isWebview()
  const setTokens = useSetAtom(tokensAtom)
  const { showCreateBoardModal } = useCreateBoardModal()
  const { bottomSheetModal } = useModal()
  const { showToast, hideToast } = useToast()
  const { mutate } = useSWRConfig()
  const router = useRouter()

  const handleMobileMessage = useCallback(
    (event: MessageEvent) => {
      try {
        if (typeof event.data !== 'string') return
        const { type, data } = JSON.parse(event.data)

        if (type === MOBILE_EVENTS.ROUTE_CHANGE && data?.path) {
          // 푸시 알림을 통해 이동하는 경우, data.path가 full url이라, router.push()를 하려면 hostname을 제거해야 한다.
          const { pathname, query, hash } = parseURL(data?.path ?? '')
          const queryString = new URLSearchParams(query).toString()

          // 엡에서 이벤트 전송 시, user_notice_id(알림 아이디)가 있으면 푸시 알림을 통해 이동하는 것이다.
          // 알림 읽음 처리를 한다.
          if (data?.user_notice_id) {
            const match = data?.path.match(boardPageRegex)
            // 질문과 답변 상세 페이지이면 읽음 처리
            if (match) {
              handleNotificationRead([Number(data.user_notice_id)]).then(() => {
                mutate(API_PATH.MyNotices)
                router.replace(
                  `${pathname}/?user_notice_id=${data.user_notice_id}${
                    data?.type ? '&notice_type=' + data.type : ''
                  }`,
                )
              })
              return
            }
          }

          router.push(
            `${pathname}${queryString ? `?${queryString}` : ''}${hash}`,
          )
        }

        if (type === MOBILE_EVENTS.SHOW_CLOSE_TOAST) {
          showToast({
            title: `'뒤로' 버튼을 한 번 더 누르시면 앱이 종료됩니다.`,
            bottom: 68,
            appToast: true,
          })
        }

        if (type === MOBILE_EVENTS.HIDE_CLOSE_TOAST) {
          hideToast()
        }

        // 1. 웹에서 로그아웃 시, 앱에 LOGOUT 이벤트 전송
        // 2. 앱에서는 LOGOUT 이벤트를 받아, 앱의 토큰을 제거한다.
        // 3. 앱에서는 웹에 WEB_LOGOUT 전송을 하여, 웹의 토큰을 제거한다.
        if (type === MOBILE_EVENTS.WEB_LOGOUT) {
          weblogout({ type: 'logout' })
        }

        // 앱에서 회원탈퇴 시, 웹에서 로그아웃 처리
        if (type === MOBILE_EVENTS.WEB_UNREGISTER) {
          weblogout({ type: 'unregister' })

          TagManager.dataLayer({
            dataLayer: {
              event: 'unregistered',
            },
          })
        }

        if (type === MOBILE_EVENTS.WEB_LOGIN) {
          if (data?.accessToken && data?.refreshToken) {
            setAuthToken({
              accessToken: data.accessToken,
              refreshToken: data.refreshToken,
            })
            setTokens({
              accessToken: data.accessToken,
              refreshToken: data.refreshToken,
            })
            bottomSheetModal.hide()
          }

          // 웹의 Auth와 동일하게 회원가입/로그인 성공 후 GTM 이벤트 전송
          TagManager.dataLayer({
            dataLayer: {
              event: data?.action === 'NEW_USER' ? 'sign_up' : 'login',
              page_url: cleanDuplicateHost(
                `${window.location.origin}${data?.redirectPath ?? ''}`,
              ),
              method: data?.authProvider,
            },
          })

          // 기본적으로 앱에서 로그인 시, 로그인을 시도한 앱화면으로 돌아간다.
          // 기본 앱화면이 아닌 별도 처리가 필요한 경우, 다음 조건문을 사용한다.
          if (data?.redirectPath) {
            const { pathname, query } = parseURL(data?.redirectPath ?? '')
            // 1. 질문 작성 풀스크린 모달을 연다.
            if (data?.redirectPath.includes(ACTION_TYPES.CREATE_BOARD))
              return showCreateBoardModal()
            // 2. 플러스 버튼 클릭 시, 플러스 버튼 모달을 연다.
            if (query.event) return showPlusForm(query.event)

            // 3. 질문과 답변 상세 페이지로 이동
            // usePVThreshold를 사용하여 로그인 여부를 체크하고 로그인 성공 후 이동하는데,
            // 모바일 앱에서는 이미 리스트 페이지로 이동한 상태이므로, 상세 페이지로 이동이 안되는 문제가 있다.
            const isDetailPage = checkDetailPagePath(pathname, [
              URL_PATH.Boards,
            ])
            if (isDetailPage || pathname === URL_PATH.MyCareDeleteAccount)
              router.replace(pathname)
            // 4. AI 진료노트 페이지로 이동. private route이므로, 로그인 직후 마이케어 메인으로 이동하기 때문에, 손수 이동시킨다.
            if (pathname === URL_PATH.MyNotes) {
              setTimeout(() => {
                router.replace(URL_PATH.MyNotes)
              }, 100)
            }
          }
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    // TODO: dependency array에 너무 많은 게 들어있다. 불필요한 리렌더링을 막고자 여길 비워둔다.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    if (!isApp) return
    // android
    document.addEventListener('message', (event) =>
      handleMobileMessage(event as MessageEvent),
    )
    // ios
    window.addEventListener('message', handleMobileMessage)
  }, [isApp, handleMobileMessage])

  useEffect(() => {
    if (!isApp) return

    Sentry.withScope((scope) => {
      const appVersion = getVersionFromCookie()
      scope.setExtra('appVersion', appVersion)
    })
  }, [isApp])
}

export default useAppMessage
